import React from 'react';
import cl from 'classnames';

import { IconsEnum } from '../../../../../../../assets/icons/types';

import {
  FetchLifestylesCacheKeys,
  UpdateLifestyleCacheAction
} from '../../../../../lifestylesTypes';

import { FetchLifestylesSetsCacheKeys } from '../../../../../../lifestylesSets/lifestylesSetsTypes';

import { PureTooltipIconButtonHelper } from '../../../../../../../helpers/buttons/PureTooltipIconButtonHelper';

import useLifestylesListItemLightboxFavoriteButton from './useLifestylesListItemLightboxFavoriteButton';

import { LifestylesListItemLightboxFavoriteButtonLifestyle } from './LifestylesListItemLightboxFavoriteButton.types';

import { words } from '../../../../../../../locales/keys';

interface LifestylesListItemLightboxFavoriteButtonProps {
  lifestyle: LifestylesListItemLightboxFavoriteButtonLifestyle;
  lifestylesCacheKeys?: FetchLifestylesCacheKeys;
  lifestylesSetCacheKeys?: FetchLifestylesSetsCacheKeys;
  updateLifestyleCache: UpdateLifestyleCacheAction<LifestylesListItemLightboxFavoriteButtonLifestyle>;
}

function LifestylesListItemLightboxFavoriteButton({
  lifestyle,
  lifestylesCacheKeys,
  lifestylesSetCacheKeys,
  updateLifestyleCache
}: LifestylesListItemLightboxFavoriteButtonProps) {
  const { handleToggleFavorite } = useLifestylesListItemLightboxFavoriteButton({
    lifestyle,
    lifestylesCacheKeys,
    lifestylesSetCacheKeys,
    updateLifestyleCache
  });

  return (
    <PureTooltipIconButtonHelper
      className={cl(
        'py-2 pl-2 pr-2 rounded-md inline-flex items-center whitespace-nowrap text-sm font-medium leading-6 focus:ring-white focus:ring-offset-0 hover:bg-white hover:bg-opacity-10 text-gray-300 hover:text-white',
        lifestyle.favorite ? 'text-pink-600' : null
      )}
      icon={
        lifestyle.favorite ? IconsEnum.HEART_SOLID : IconsEnum.HEART_OUTLINE
      }
      iconClassName={cl('h-6 w-6', lifestyle.favorite ? 'p-0.5' : null)}
      tooltipSingleton
      tooltipI18nText={
        lifestyle.favorite ? words.removeFromFavorites : words.addToFavorites
      }
      onClick={handleToggleFavorite}
    />
  );
}

export default LifestylesListItemLightboxFavoriteButton;
