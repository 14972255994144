import { GraphQLClient } from 'graphql-request';

import { ID } from '../../../../types';

import { FetchLifestylesSetByUserIdScopeType } from '../../lifestylesSetsTypes';

import { GRAPHQL_API_URI } from '../../../../config';

interface FetchLifestylesSetByUserIdProps {
  query: string;
  input: FetchLifestylesSetByUserIdInput;
}

const graphQLClient = new GraphQLClient(GRAPHQL_API_URI, {
  credentials: 'include',
  mode: 'cors'
});

export interface FetchLifestylesSetByUserIdInput {
  userId: ID;
  scope?: FetchLifestylesSetByUserIdScopeType;
}

export function fetchLifestylesSetByUserId({
  query,
  input
}: FetchLifestylesSetByUserIdProps) {
  return graphQLClient.request(query, input);
}
