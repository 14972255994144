import React, { Fragment } from 'react';

import {
  FetchLifestylesCacheKeys,
  OnSelectedLifestylesSidebarCloseAction,
  OnSelectedLifestylesSidebarOpenAction,
  UpdateLifestyleCacheAction
} from '../../../../../lifestylesTypes';

import {
  UpdateLifestylesSetCacheAction,
  FetchLifestylesSetsCacheKeys
} from '../../../../../../lifestylesSets/lifestylesSetsTypes';

import { LifestylesListItemLightboxDownloadButton } from '../LifestylesListItemLightboxDownloadButton';
import { LifestylesListItemLightboxFavoriteButton } from '../LifestylesListItemLightboxFavoriteButton';
import { LifestylesListItemLightboxSelectButton } from '../LifestylesListItemLightboxSelectButton';

import { CheckPermissions } from '../../../../../../../helpers/CheckPermissions';

import {
  LifestylesListItemLightboxBottomButtonsLifestyle,
  LifestylesListItemLightboxBottomButtonsLifestylesSet
} from './LifestylesListItemLightboxBottomButtons.types';

import { LifestylesPermissions } from '../../../../../lifestylesConstants';

interface LifestylesListItemLightboxBottomButtonsProps {
  lifestyle: LifestylesListItemLightboxBottomButtonsLifestyle;
  lifestylesSet: LifestylesListItemLightboxBottomButtonsLifestylesSet | null;
  lifestylesCacheKeys?: FetchLifestylesCacheKeys;
  lifestylesSetCacheKeys?: FetchLifestylesSetsCacheKeys;
  onSelectedLifestylesSidebarOpen: OnSelectedLifestylesSidebarOpenAction;
  onSelectedLifestylesSidebarClose: OnSelectedLifestylesSidebarCloseAction;
  updateLifestylesSetCache: UpdateLifestylesSetCacheAction<LifestylesListItemLightboxBottomButtonsLifestylesSet>;
  updateLifestyleCache: UpdateLifestyleCacheAction<LifestylesListItemLightboxBottomButtonsLifestyle>;
}

function LifestylesListItemLightboxBottomButtons({
  lifestyle,
  lifestylesSet,
  lifestylesCacheKeys,
  lifestylesSetCacheKeys,
  onSelectedLifestylesSidebarOpen,
  onSelectedLifestylesSidebarClose,
  updateLifestylesSetCache,
  updateLifestyleCache
}: LifestylesListItemLightboxBottomButtonsProps) {
  return (
    <Fragment>
      <LifestylesListItemLightboxSelectButton
        lifestyle={lifestyle}
        lifestylesSet={lifestylesSet}
        lifestylesSetCacheKeys={lifestylesSetCacheKeys}
        onSelectedLifestylesSidebarOpen={onSelectedLifestylesSidebarOpen}
        onSelectedLifestylesSidebarClose={onSelectedLifestylesSidebarClose}
        updateLifestylesSetCache={updateLifestylesSetCache}
      />
      <LifestylesListItemLightboxFavoriteButton
        lifestyle={lifestyle}
        lifestylesCacheKeys={lifestylesCacheKeys}
        lifestylesSetCacheKeys={lifestylesSetCacheKeys}
        updateLifestyleCache={updateLifestyleCache}
      />
      <CheckPermissions
        action={LifestylesPermissions.READ_LIFESTYLE_DOWNLOAD_BUTTON}
      >
        <LifestylesListItemLightboxDownloadButton lifestyle={lifestyle} />
      </CheckPermissions>
    </Fragment>
  );
}

export default LifestylesListItemLightboxBottomButtons;
