import { useCallback, useMemo } from 'react';
import size from 'lodash/size';
import some from 'lodash/some';
import compact from 'lodash/compact';

import {
  UpdateLifestylesSetCacheAction,
  FetchLifestylesSetsCacheKeys
} from '../../../../../../lifestylesSets/lifestylesSetsTypes';

import {
  OnSelectedLifestylesSidebarOpenAction,
  OnSelectedLifestylesSidebarCloseAction
} from '../../../../../lifestylesTypes';

import { TOGGLE_LIFESTYLE_IN_LIFESTYLES_SET_QUERY } from '../../../../../../lifestylesSets/queries/toggleLifestyleInLifestylesSet.query';

import { useToggleLifestyleInLifestylesSet } from '../../../../../../lifestylesSets/hooks/useToggleLifestyleInLifestylesSet';

import { useShowToastOnErrorChange } from '../../../../../../../common/hooks/useShowToastOnErrorChange';

import {
  LifestylesListItemSelectButtonLifestyle,
  LifestylesListItemSelectButtonLifestylesSet,
  LifestylesListItemSelectButtonOnSelect
} from './LifestylesListItemSelectButton.types';
import { LifestylesSetCache } from '../../../../../../lifestylesSets/LifestylesSetCache';

interface UseLifestylesListItemSelectButtonOptions {
  lifestyle: LifestylesListItemSelectButtonLifestyle;
  lifestylesSet: LifestylesListItemSelectButtonLifestylesSet | null;
  lifestylesSetCacheKeys?: FetchLifestylesSetsCacheKeys;
  onSelectedLifestylesSidebarOpen: OnSelectedLifestylesSidebarOpenAction;
  onSelectedLifestylesSidebarClose: OnSelectedLifestylesSidebarCloseAction;
  updateLifestylesSetCache: UpdateLifestylesSetCacheAction<LifestylesListItemSelectButtonLifestylesSet>;
  onSelect?: LifestylesListItemSelectButtonOnSelect;
}

function useLifestylesListItemSelectButton({
  lifestyle,
  lifestylesSet,
  lifestylesSetCacheKeys,
  onSelectedLifestylesSidebarOpen,
  onSelectedLifestylesSidebarClose,
  updateLifestylesSetCache,
  onSelect
}: UseLifestylesListItemSelectButtonOptions) {
  const isSelected = useMemo<boolean>(() => {
    if (!lifestylesSet || !lifestylesSet.selectedLifestyles) {
      return false;
    }
    return some(
      lifestylesSet.selectedLifestyles,
      (selectedLifestyle) =>
        selectedLifestyle?.lifestyle.uuid === lifestyle.uuid
    );
  }, [lifestyle, lifestylesSet]);

  const {
    toggleLifestyleInLifestylesSetErrorMessage,
    toggleLifestyleInLifestylesSet
  } = useToggleLifestyleInLifestylesSet({
    query: TOGGLE_LIFESTYLE_IN_LIFESTYLES_SET_QUERY,
    cacheKeys: compact([
      LifestylesSetCache.showCacheKey(),
      ...(lifestylesSetCacheKeys || [])
    ]),
    lifestyle,
    updateLifestylesSetCache
  });

  const selectedLifestylesAmount = size(lifestylesSet?.selectedLifestyles);

  const handleAfterToggle = useCallback<() => void>(() => {
    if (selectedLifestylesAmount === 0 && !isSelected) {
      onSelectedLifestylesSidebarOpen();
    }
    if (selectedLifestylesAmount === 1 && isSelected) {
      onSelectedLifestylesSidebarClose();
    }
  }, [
    isSelected,
    selectedLifestylesAmount,
    onSelectedLifestylesSidebarOpen,
    onSelectedLifestylesSidebarClose
  ]);

  const lifestylesSetUuid = lifestylesSet?.uuid;
  const lifestyleId = lifestyle?.id;

  const handleToggleSelected = useCallback<() => void>(() => {
    if (!lifestylesSetUuid || !lifestyleId) {
      return;
    }

    toggleLifestyleInLifestylesSet({
      uuid: lifestylesSetUuid,
      lifestyleId
    }).then((res) =>
      onSelect?.(
        res?.toggleLifestyleInLifestylesSet?.record?.selectedLifestyles
      )
    );

    handleAfterToggle();
  }, [
    lifestylesSetUuid,
    lifestyleId,
    toggleLifestyleInLifestylesSet,
    handleAfterToggle,
    onSelect
  ]);

  useShowToastOnErrorChange({
    error: toggleLifestyleInLifestylesSetErrorMessage
  });

  return {
    isSelected,
    handleToggleSelected
  };
}

export default useLifestylesListItemSelectButton;
