import { FetchSelectedLifestylesSortTypes } from './selectedLifestylesTypes';

export const INITIAL_SELECTED_LIFESTYLES_FILTERS = {};
export const INITIAL_SELECTED_LIFESTYLES_PAGE = 1;
export const INITIAL_SELECTED_LIFESTYLES_SORT = [
  FetchSelectedLifestylesSortTypes.CREATED_AT_DESC
];
export const INITIAL_SELECTED_LIFESTYLES_LIMIT = 50;

export enum SelectedLifestylesPermissions {
  READ_SELECTED_LIFESTYLES_DOWNLOAD_BUTTON = 'read_selected_lifestyles_download_button',
  READ_SELECTED_LIFESTYLE_FAVORITE_BUTTON = 'read_selected_lifestyle_favorite_button'
}
