import { gql } from 'graphql-request';

import {
  LifestylesSetID,
  LifestylesSetSelectedLifestylesID,
  LifestylesSetSelectedLifestylesLifestyleID,
  LifestylesSetSelectedLifestylesLifestyleImageFile,
  LifestylesSetSelectedLifestylesLifestyleImageUUID,
  LifestylesSetSelectedLifestylesLifestyleName,
  LifestylesSetSelectedLifestylesLifestyleUUID,
  LifestylesSetSelectedLifestylesUUID,
  LifestylesSetSelectedLifestylesLifestyleCategoryUUID,
  LifestylesSetSelectedLifestylesLifestyleCategoryName,
  LifestylesSetSelectedLifestylesLifestyleCategoryLocalizedName,
  LifestylesSetUUID,
  LifestylesSetSelectedLifestylesLifestyleNda,
  LifestylesSetSelectedLifestylesLifestyleBlocked,
  LifestylesSetSelectedLifestylesLifestyleFavorite
} from '../lifestylesSetsTypes';

export interface FetchLifestylesSetByUserIdQueryResponse {
  id: LifestylesSetID;
  uuid: LifestylesSetUUID;
  selectedLifestyles: {
    id: LifestylesSetSelectedLifestylesID;
    uuid: LifestylesSetSelectedLifestylesUUID;
    lifestyle: {
      id: LifestylesSetSelectedLifestylesLifestyleID;
      uuid: LifestylesSetSelectedLifestylesLifestyleUUID;
      name: LifestylesSetSelectedLifestylesLifestyleName;
      nda: LifestylesSetSelectedLifestylesLifestyleNda;
      blocked: LifestylesSetSelectedLifestylesLifestyleBlocked;
      favorite: LifestylesSetSelectedLifestylesLifestyleFavorite;
      image: {
        uuid: LifestylesSetSelectedLifestylesLifestyleImageUUID;
        file: LifestylesSetSelectedLifestylesLifestyleImageFile;
      };
      category: {
        uuid: LifestylesSetSelectedLifestylesLifestyleCategoryUUID;
        name: LifestylesSetSelectedLifestylesLifestyleCategoryName;
        localizedName: LifestylesSetSelectedLifestylesLifestyleCategoryLocalizedName;
      };
    };
  }[];
}

export const FETCH_LIFESTYLES_SET_BY_USER_ID_QUERY = gql`
  query LifestylesSetByUserId($userId: ID!, $scope: ID) {
    lifestylesSetByUserId(userId: $userId, scope: $scope) {
      id
      uuid
      selectedLifestyles {
        id
        uuid
        lifestyle {
          id
          uuid
          name
          nda
          blocked
          favorite
          image {
            uuid
            file
          }
          category {
            uuid
            name
            localizedName
          }
        }
      }
    }
  }
`;
