import { CreatedAt, DeviceNanoId, ID, NanoID, UUID } from '../../../types';

import {
  CategoryName,
  CategoryUUID,
  CategoryID,
  CategoryLocalizedName
} from '../../categories/categoriesTypes';

import {
  ImageFile,
  ImageName,
  ImageSize,
  ImageUUID
} from '../../images/imagesTypes';

import { UserID } from '../../users/usersTypes';

import { ImageID } from '../../images/imagesTypes';

import {
  MaxFileFile,
  MaxFileID,
  MaxFileName,
  MaxFileSize,
  MaxFileUUID
} from '../../maxFiles/maxFilesTypes';

export type LifestyleID = ID;
export type LifestyleUUID = UUID;
export type LifestyleNanoID = NanoID;
export type LifestyleRenderTypeID = ID;
export type LifestyleRenderTypeName = string;
export type LifestyleBlocked = boolean;
export type LifestyleFavorite = boolean;
export type LifestyleName = string;
export type LifestyleNda = boolean;
export type LifestyleCreatedAt = CreatedAt;
export type LifestyleDeviceNanoId = DeviceNanoId;

export type LifestyleImageID = ImageID;
export type LifestyleImageUUID = ImageUUID;
export type LifestyleImageFile = ImageFile;
export type LifestyleImageSize = ImageSize;
export type LifestyleImageName = ImageName;

export type LifestyleMaxFileID = MaxFileID;
export type LifestyleMaxFileName = MaxFileName;
export type LifestyleMaxFileSize = MaxFileSize;
export type LifestyleMaxFileUUID = MaxFileUUID;
export type LifestyleMaxFileFile = MaxFileFile;

export type LifestyleProjectNameID = CategoryID;
export type LifestyleProjectNameUUID = CategoryUUID;
export type LifestyleProjectNameName = CategoryName;

export type LifestyleCategoryID = CategoryID;
export type LifestyleCategoryUUID = CategoryUUID;
export type LifestyleCategoryName = CategoryName;
export type LifestyleCategoryLocalizedName = CategoryLocalizedName;

export type LifestyleClientID = UserID;

export type LifestyleAuthorID = CategoryID;

export type LifestyleStyleID = CategoryID;

export type LifestyleToneID = CategoryID;

export type LifestyleGammaID = CategoryID;

export type LifestyleMainColorID = CategoryID;

export type LifestyleAccentColorID = CategoryID;

export type LifestyleMaterialID = CategoryID;

export type LifestyleTagID = CategoryID;

export const enum LifestyleFields {
  NAME = 'name',
  AUTHOR_ID = 'authorId',
  CLIENT_IDS = 'clientIds',
  PROJECT_NAME_ID = 'projectNameId',
  CATEGORY_ID = 'categoryId',
  STYLE_IDS = 'styleIds',
  TONE_ID = 'toneId',
  GAMMA_ID = 'gammaId',
  MAIN_COLOR_IDS = 'mainColorIds',
  ACCENT_COLOR_IDS = 'accentColorIds',
  MATERIAL_IDS = 'materialIds',
  TAG_IDS = 'tagIds',
  NDA = 'nda',
  IMAGE_IDS = 'imageIds',
  MAX_FILE_IDS = 'maxFileIds',
  RENDER_TYPE_IDS = 'renderTypeIds'
}
