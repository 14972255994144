import { useCallback } from 'react';

import { ProjectUUID } from '../../../../../../../../projects/projectsTypes';
import { TaskUUID } from '../../../../../../../../tasks/tasksTypes';
import {
  LifestyleID,
  LifestyleUUID
} from '../../../../../../../lifestylesTypes';

import { useSubmitLifestyleToProject } from '../../../../../../../hooks/useSubmitLifestyleToProject';
import { useSubmitLifestyleToTask } from '../../../../../../../hooks/useSubmitLifestyleToTask';

interface SubmitLifestyleToProjectOrTaskOptionsLifestyle {
  uuid: LifestyleUUID;
  id: LifestyleID;
}

interface SubmitLifestyleToProjectOrTaskOptions {
  lifestyle: SubmitLifestyleToProjectOrTaskOptionsLifestyle;
}

interface HandleSubmitLifestyleToProjectOrTaskProps {
  projectUuid: ProjectUUID;
  taskUuid: TaskUUID | null;
}

function useSubmitLifestyleToProjectOrTask({
  lifestyle
}: SubmitLifestyleToProjectOrTaskOptions) {
  const {
    submitLifestyleToTask,
    submitLifestyleToTaskError,
    submitLifestyleToTaskIsLoading,
    submitLifestyleToTaskReset
  } = useSubmitLifestyleToTask({ lifestyle });

  const {
    submitLifestyleToProject,
    submitLifestyleToProjectError,
    submitLifestyleToProjectIsLoading,
    submitLifestyleToProjectReset
  } = useSubmitLifestyleToProject({ lifestyle });

  const submitLifestyleToProjectOrTask = useCallback<
    ({
      projectUuid,
      taskUuid
    }: HandleSubmitLifestyleToProjectOrTaskProps) => Promise<unknown>
  >(
    async ({ projectUuid, taskUuid }) => {
      if (taskUuid) {
        return submitLifestyleToTask(taskUuid);
      }

      return submitLifestyleToProject(projectUuid);
    },
    [submitLifestyleToTask, submitLifestyleToProject]
  );

  const submitLifestyleToProjectOrTaskReset = useCallback<() => void>(() => {
    submitLifestyleToProjectReset();
    submitLifestyleToTaskReset();
  }, [submitLifestyleToProjectReset, submitLifestyleToTaskReset]);

  return {
    submitLifestyleToProjectOrTask,
    submitLifestyleToProjectOrTaskReset,
    submitLifestyleToProjectOrTaskError:
      submitLifestyleToTaskError || submitLifestyleToProjectError,
    submitLifestyleToProjectOrTaskIsLoading:
      submitLifestyleToTaskIsLoading || submitLifestyleToProjectIsLoading
  };
}

export default useSubmitLifestyleToProjectOrTask;
