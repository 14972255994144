import React from 'react';
import cl from 'classnames';

import { IconsEnum } from '../../../../../../../assets/icons/types';

import {
  FetchLifestylesCacheKeys,
  UpdateLifestyleCacheAction
} from '../../../../../lifestylesTypes';

import { FetchLifestylesSetsCacheKeys } from '../../../../../../lifestylesSets/lifestylesSetsTypes';

import { PureTooltipIconButtonHelper } from '../../../../../../../helpers/buttons/PureTooltipIconButtonHelper';

import useLifestylesListItemFavoriteButton from './useLifestylesListItemFavoriteButton';

import { TooltipPlacement } from '../../../../../../../helpers/tooltips/tooltipsConstants';

import { LifestylesListItemFavoriteButtonLifestyle } from './LifestylesListItemFavoriteButton.types';

import { words } from '../../../../../../../locales/keys';

interface LifestylesListItemFavoriteButtonProps {
  lifestyle: LifestylesListItemFavoriteButtonLifestyle;
  lifestylesCacheKeys?: FetchLifestylesCacheKeys;
  lifestylesSetCacheKeys?: FetchLifestylesSetsCacheKeys;
  updateLifestyleCache: UpdateLifestyleCacheAction<LifestylesListItemFavoriteButtonLifestyle>;
}

function LifestylesListItemFavoriteButton({
  lifestyle,
  lifestylesCacheKeys,
  lifestylesSetCacheKeys,
  updateLifestyleCache
}: LifestylesListItemFavoriteButtonProps) {
  const { handleToggleFavorite } = useLifestylesListItemFavoriteButton({
    lifestyle,
    lifestylesCacheKeys,
    lifestylesSetCacheKeys,
    updateLifestyleCache
  });

  return (
    <PureTooltipIconButtonHelper
      className={cl(
        'focus:ring-offset-0 items-center rounded-full',
        lifestyle.favorite
          ? 'text-pink-600 hover:text-pink-500 flex p-0.5'
          : 'text-gray-400 dark:text-gray-500 hover:text-pink-600 dark:hover:text-pink-600 flex p-1'
      )}
      icon={
        lifestyle.favorite ? IconsEnum.HEART_SOLID : IconsEnum.HEART_OUTLINE
      }
      tooltipI18nText={
        lifestyle.favorite ? words.removeFromFavorites : words.addToFavorites
      }
      tooltipPlacement={TooltipPlacement.TOP}
      onClick={handleToggleFavorite}
    />
  );
}

export default LifestylesListItemFavoriteButton;
