import React from 'react';

import { LifestyleID, LifestyleUUID } from '../../../../../lifestylesTypes';
import { ClassName } from '../../../../../../../types';
import { IconsEnum } from '../../../../../../../assets/icons/types';

import { useSubmitLifestyleToProjectOrTask } from './hooks/useSubmitLifestyleToProjectOrTask';

import { SelectProjectAndTaskModalButton } from '../../../../../../common/components/modalButtons/SelectProjectAndTaskModalButton';

import { TooltipPlacement } from '../../../../../../../helpers/tooltips/tooltipsConstants';
import { lifestylesKeys, words } from '../../../../../../../locales/keys';

interface SubmitLifestyleToProjectOrTaskModalButtonLifestyle {
  uuid: LifestyleUUID;
  id: LifestyleID;
}

interface SubmitLifestyleToProjectOrTaskModalButtonProps {
  lifestyle: SubmitLifestyleToProjectOrTaskModalButtonLifestyle;
  className?: ClassName;
  tooltipPlacement?: TooltipPlacement;
}

function SubmitLifestyleToProjectOrTaskModalButton({
  lifestyle,
  className,
  tooltipPlacement = TooltipPlacement.LEFT
}: SubmitLifestyleToProjectOrTaskModalButtonProps) {
  const {
    submitLifestyleToProjectOrTask,
    submitLifestyleToProjectOrTaskError,
    submitLifestyleToProjectOrTaskIsLoading,
    submitLifestyleToProjectOrTaskReset
  } = useSubmitLifestyleToProjectOrTask({ lifestyle });

  return (
    <SelectProjectAndTaskModalButton
      className={
        className ||
        'bg-white dark:bg-gray-900 dark:hover:bg-gray-700 dark:text-gray-300 focus:ring-offset-0 font-medium hover:bg-gray-200 inline-flex items-center p-1 rounded-md text-gray-700 text-sm pointer-events-auto shadow dark:glow'
      }
      icon={IconsEnum.PAPER_CLIP_SOLID}
      iconClassName="h-5 w-5 stroke-1.75"
      i18nSubmitText={words.attach}
      i18nTitle={lifestylesKeys.attachSelected}
      tooltipSingleton
      tooltipI18nText={words.attach}
      tooltipPlacement={tooltipPlacement}
      isLoading={submitLifestyleToProjectOrTaskIsLoading}
      errorMessage={submitLifestyleToProjectOrTaskError}
      onClose={submitLifestyleToProjectOrTaskReset}
      onSubmit={submitLifestyleToProjectOrTask}
    />
  );
}

export default SubmitLifestyleToProjectOrTaskModalButton;
