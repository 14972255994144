import { useCallback } from 'react';
import compact from 'lodash/compact';

import {
  FetchLifestylesCacheKeys,
  UpdateLifestyleCacheAction
} from '../../../../../lifestylesTypes';

import { FetchLifestylesSetsCacheKeys } from '../../../../../../lifestylesSets/lifestylesSetsTypes';

import {
  TOGGLE_LIFESTYLE_FAVORITE_QUERY,
  ToggleLifestyleFavoriteQueryResponse
} from '../../../../../queries/toggleLifestyleFavorite.query';

import { useToggleLifestyleFavorite } from '../../../../../hooks/useToggleLifestyleFavorite';

import { useShowToastOnErrorChange } from '../../../../../../../common/hooks/useShowToastOnErrorChange';

import { LifestylesListItemFavoriteButtonLifestyle } from './LifestylesListItemFavoriteButton.types';
import { LifestyleCache } from '../../../../../LifestyleCache';
import { LifestylesSetCache } from '../../../../../../lifestylesSets/LifestylesSetCache';

interface useLifestylesListItemFavoriteButtonProps {
  lifestyle: LifestylesListItemFavoriteButtonLifestyle;
  lifestylesCacheKeys?: FetchLifestylesCacheKeys;
  lifestylesSetCacheKeys?: FetchLifestylesSetsCacheKeys;
  updateLifestyleCache: UpdateLifestyleCacheAction<LifestylesListItemFavoriteButtonLifestyle>;
}

function useLifestylesListItemFavoriteButton({
  lifestyle,
  lifestylesCacheKeys,
  lifestylesSetCacheKeys,
  updateLifestyleCache
}: useLifestylesListItemFavoriteButtonProps) {
  const { toggleLifestyleFavorite, toggleLifestyleFavoriteErrorMessage } =
    useToggleLifestyleFavorite<ToggleLifestyleFavoriteQueryResponse>({
      query: TOGGLE_LIFESTYLE_FAVORITE_QUERY,
      cacheKeys: compact([
        LifestyleCache.indexCacheKey(),
        LifestylesSetCache.showCacheKey(),
        ...(lifestylesCacheKeys || []),
        ...(lifestylesSetCacheKeys || [])
      ]),
      updateLifestyleCache
    });

  const handleToggleFavorite = useCallback<() => void>(
    () => toggleLifestyleFavorite({ uuid: lifestyle.uuid }),
    [lifestyle, toggleLifestyleFavorite]
  );

  useShowToastOnErrorChange({ error: toggleLifestyleFavoriteErrorMessage });

  return {
    handleToggleFavorite
  };
}

export default useLifestylesListItemFavoriteButton;
