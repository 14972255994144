import React, { memo, useCallback, useMemo } from 'react';
import some from 'lodash/some';

import { OnLightboxOpenAction } from '../../../../../helpers/LightboxWrapper';

import {
  UpdateLifestylesSetCacheAction,
  FetchLifestylesSetsCacheKeys
} from '../../../../lifestylesSets/lifestylesSetsTypes';

import {
  FetchLifestylesCacheKeys,
  OnSelectedLifestylesSidebarCloseAction,
  OnSelectedLifestylesSidebarOpenAction,
  UpdateLifestyleCacheAction,
  LifestyleNanoID
} from '../../../lifestylesTypes';
import {
  LifestylesListItemLifestyle,
  LifestylesListItemLifestylesSet,
  LifestylesListItemOnLifestylesSelect
} from './LifestylesListItem.types';
import { IconsEnum } from '../../../../../assets/icons/types';

import { LifestylesListItemEditLink } from '../LifestylesListItemEditLink';
import { DownloadLifestyleModalButton } from '../../buttons/DownloadLifestyleModalButton';
import { LifestylesListItemFavoriteButton } from './components/LifestylesListItemFavoriteButton';
import { LifestylesListItemNda } from './components/LifestylesListItemNda';
import { LifestylesListItemOpenLightboxButton } from './components/LifestylesListItemOpenLightboxButton';
import { LifestylesListItemPreview } from './components/LifestylesListItemPreview';
import { LifestylesListItemSelectButton } from './components/LifestylesListItemSelectButton';
import { LifestylesListItemUpdateImageVersions } from './components/LifestylesListItemUpdateImageVersions';
import { SubmitLifestyleToProjectOrTaskModalButton } from './components/SubmitLifestyleToProjectOrTaskModalButton';

import { CheckPermissions } from '../../../../../helpers/CheckPermissions';
import { TextWithTooltip } from '../../../../../helpers/TextWithTooltip';
import { TooltipSingletonSourceWrapper } from '../../../../../helpers/tooltips/TooltipSingletonSourceWrapper';

import { TooltipPlacement } from '../../../../../helpers/tooltips/tooltipsConstants';

import { LifestylesPermissions } from '../../../lifestylesConstants';

import { words } from '../../../../../locales/keys';

interface LifestylesListItemProps {
  lifestyle: LifestylesListItemLifestyle;
  lifestylesSet: LifestylesListItemLifestylesSet | null;
  lifestylesCacheKeys?: FetchLifestylesCacheKeys;
  lifestylesSetCacheKeys?: FetchLifestylesSetsCacheKeys;
  onLightboxOpen: OnLightboxOpenAction;
  onSelectedLifestylesSidebarOpen: OnSelectedLifestylesSidebarOpenAction;
  onSelectedLifestylesSidebarClose: OnSelectedLifestylesSidebarCloseAction;
  updateLifestylesSetCache: UpdateLifestylesSetCacheAction<LifestylesListItemLifestylesSet>;
  updateLifestyleCache: UpdateLifestyleCacheAction<LifestylesListItemLifestyle>;
  onLifestylesSelect?: LifestylesListItemOnLifestylesSelect;
  onLifestyleEditButtonMouseEnter?: (lifestyleNanoId: LifestyleNanoID) => void;
}

function LifestylesListItem({
  lifestyle,
  lifestylesSet,
  lifestylesCacheKeys,
  lifestylesSetCacheKeys,
  onLightboxOpen,
  onSelectedLifestylesSidebarOpen,
  onSelectedLifestylesSidebarClose,
  updateLifestylesSetCache,
  updateLifestyleCache,
  onLifestylesSelect,
  onLifestyleEditButtonMouseEnter
}: LifestylesListItemProps) {
  const handleOpenLightbox = useCallback(() => {
    if (lifestyle.image) {
      onLightboxOpen(lifestyle.image);
    }
  }, [lifestyle, onLightboxOpen]);

  const isSelected = useMemo<boolean>(() => {
    if (!lifestylesSet || !lifestylesSet.selectedLifestyles) {
      return false;
    }
    return some(
      lifestylesSet.selectedLifestyles,
      (selectedLifestyle) =>
        selectedLifestyle?.lifestyle.uuid === lifestyle.uuid
    );
  }, [lifestyle, lifestylesSet]);

  const handleEditButtonMouseEnter = useCallback<() => void>(() => {
    onLifestyleEditButtonMouseEnter?.(lifestyle.nanoId);
  }, [onLifestyleEditButtonMouseEnter, lifestyle.nanoId]);

  return (
    <div className="w-80 max-w-full mx-auto relative rounded-md group">
      <LifestylesListItemPreview
        lifestyle={lifestyle}
        onLightboxOpen={handleOpenLightbox}
      />
      <div className="text-sm p-3">
        <div className="font-medium truncate">
          <TextWithTooltip
            text={lifestyle.name}
            tooltipPlacement={TooltipPlacement.BOTTOM}
          />
        </div>
        <div className="text-gray-600 dark:text-gray-500 truncate">
          <LifestylesListItemNda lifestyle={lifestyle} />
          <TextWithTooltip
            text={lifestyle.category?.localizedName}
            tooltipPlacement={TooltipPlacement.BOTTOM}
          />
        </div>
        <div className="mt-3 flex justify-between items-center relative z-5">
          <LifestylesListItemSelectButton
            lifestyle={lifestyle}
            lifestylesSet={lifestylesSet}
            lifestylesSetCacheKeys={lifestylesSetCacheKeys}
            onSelectedLifestylesSidebarOpen={onSelectedLifestylesSidebarOpen}
            onSelectedLifestylesSidebarClose={onSelectedLifestylesSidebarClose}
            updateLifestylesSetCache={updateLifestylesSetCache}
            onSelect={onLifestylesSelect}
          />
          <CheckPermissions
            action={LifestylesPermissions.READ_LIFESTYLE_FAVORITE_BUTTON}
          >
            <LifestylesListItemFavoriteButton
              lifestyle={lifestyle}
              lifestylesSetCacheKeys={lifestylesSetCacheKeys}
              lifestylesCacheKeys={lifestylesCacheKeys}
              updateLifestyleCache={updateLifestyleCache}
            />
          </CheckPermissions>
        </div>
      </div>
      {isSelected ? (
        <div className="absolute inset-0 rounded-md ring-4 ring-blue-500 bg-blue-500 opacity-10 pointer-events-none" />
      ) : null}
      {lifestyle.blocked ? (
        <div className="absolute inset-0 rounded-md bg-crossed bg-red-200 bg-opacity-10 pointer-events-none" />
      ) : null}
      <div className="absolute inset-0 pointer-events-none flex sm:opacity-0 group-hover:opacity-100 focus-within:opacity-100 items-start justify-end">
        <div className="flex flex-col space-y-1 pointer-events-auto p-2">
          <TooltipSingletonSourceWrapper
            placement={TooltipPlacement.LEFT}
            withArrow
          >
            <CheckPermissions
              action={LifestylesPermissions.READ_LIFESTYLE_DOWNLOAD_BUTTON}
            >
              <DownloadLifestyleModalButton
                lifestyleUuid={lifestyle.uuid}
                className="bg-white dark:bg-gray-900 dark:hover:bg-gray-700 dark:text-gray-300 focus:ring-offset-0 font-medium hover:bg-gray-200 inline-flex items-center p-1 rounded-md text-gray-700 text-sm pointer-events-auto shadow dark:glow"
                icon={IconsEnum.DOWNLOAD_SOLID}
                iconClassName="h-5 w-5"
                tooltipI18nText={words.download}
                tooltipSingleton
              />
            </CheckPermissions>

            <CheckPermissions
              action={
                LifestylesPermissions.READ_LIFESTYLE_SUBMIT_TO_TASK_OR_PROJECT_BUTTON
              }
            >
              <SubmitLifestyleToProjectOrTaskModalButton
                lifestyle={lifestyle}
              />
            </CheckPermissions>

            <LifestylesListItemOpenLightboxButton
              lifestyle={lifestyle}
              onLightboxOpen={handleOpenLightbox}
            />
            <CheckPermissions
              action={
                LifestylesPermissions.READ_LIFESTYLE_UPDATE_IMAGE_VERSION_BUTTON
              }
            >
              <LifestylesListItemUpdateImageVersions lifestyle={lifestyle} />
            </CheckPermissions>
            <CheckPermissions
              action={LifestylesPermissions.READ_LIFESTYLE_EDIT_BUTTON}
            >
              <LifestylesListItemEditLink
                lifestyle={lifestyle}
                onMouseEnter={handleEditButtonMouseEnter}
              />
            </CheckPermissions>
          </TooltipSingletonSourceWrapper>
        </div>
      </div>
    </div>
  );
}

export default memo<LifestylesListItemProps>(LifestylesListItem);
