import React from 'react';
import cl from 'classnames';

import { IconsEnum } from '../../../../../../../assets/icons/types';

import {
  UpdateLifestylesSetCacheAction,
  FetchLifestylesSetsCacheKeys
} from '../../../../../../lifestylesSets/lifestylesSetsTypes';

import {
  OnSelectedLifestylesSidebarOpenAction,
  OnSelectedLifestylesSidebarCloseAction
} from '../../../../../lifestylesTypes';

import { PureTooltipIconButtonHelper } from '../../../../../../../helpers/buttons/PureTooltipIconButtonHelper';

import useLifestylesListItemSelectButton from './useLifestylesListItemSelectButton';

import { words } from '../../../../../../../locales/keys';

import { TooltipPlacement } from '../../../../../../../helpers/tooltips/tooltipsConstants';

import {
  LifestylesListItemSelectButtonLifestyle,
  LifestylesListItemSelectButtonLifestylesSet,
  LifestylesListItemSelectButtonOnSelect
} from './LifestylesListItemSelectButton.types';

interface LifestylesListItemSelectButtonProps {
  lifestyle: LifestylesListItemSelectButtonLifestyle;
  lifestylesSet: LifestylesListItemSelectButtonLifestylesSet | null;
  lifestylesSetCacheKeys?: FetchLifestylesSetsCacheKeys;
  onSelectedLifestylesSidebarOpen: OnSelectedLifestylesSidebarOpenAction;
  onSelectedLifestylesSidebarClose: OnSelectedLifestylesSidebarCloseAction;
  updateLifestylesSetCache: UpdateLifestylesSetCacheAction<LifestylesListItemSelectButtonLifestylesSet>;
  onSelect?: LifestylesListItemSelectButtonOnSelect;
}

function LifestylesListItemSelectButton({
  lifestyle,
  lifestylesSet,
  lifestylesSetCacheKeys,
  onSelectedLifestylesSidebarOpen,
  onSelectedLifestylesSidebarClose,
  updateLifestylesSetCache,
  onSelect
}: LifestylesListItemSelectButtonProps) {
  const { isSelected, handleToggleSelected } =
    useLifestylesListItemSelectButton({
      lifestyle,
      lifestylesSet,
      lifestylesSetCacheKeys,
      onSelectedLifestylesSidebarOpen,
      onSelectedLifestylesSidebarClose,
      updateLifestylesSetCache,
      onSelect
    });

  return (
    <PureTooltipIconButtonHelper
      className={cl(
        'border font-medium hover:shadow inline-flex items-center px-2 py-1 relative rounded-md shadow-sm text-sm',
        isSelected
          ? 'text-white bg-blue-500 hover:bg-blue-600 border-transparent space-x-1'
          : 'border-gray-300 dark:border-gray-700 dark:hover:bg-gray-700 dark:text-gray-300 text-gray-700 hover:bg-gray-50'
      )}
      i18nText={isSelected ? words.selected : words.select}
      icon={isSelected ? IconsEnum.CHECK : null}
      iconClassName="h-4 w-4"
      tooltipI18nText={words.selectToAddToTheProject}
      tooltipPlacement={TooltipPlacement.TOP}
      onClick={handleToggleSelected}
    />
  );
}

export default LifestylesListItemSelectButton;
