import React, { memo, Fragment, useMemo, useCallback } from 'react';
import compact from 'lodash/compact';
import filter from 'lodash/filter';
import size from 'lodash/size';

import {
  IsFetched,
  ErrorMessage,
  IsPlaceholderData
} from '../../../../../types';

import { TogglePreventModalCloseAction } from '../../../../../helpers/modals/modalsTypes';

import {
  FetchLifestylesCacheKeys,
  OnSelectedLifestylesSidebarCloseAction
} from '../../../../lifestyles/lifestylesTypes';

import { FetchLifestylesSetsCacheKeys } from '../../../../lifestylesSets/lifestylesSetsTypes';

import { SelectedLifestylesListItem } from '../SelectedLifestylesListItem';

import { SelectedLifestylesListItemLightboxTitle } from './components/SelectedLifestylesListItemLightboxTitle';
import { SelectedLifestylesListItemLightboxBottomButtons } from './components/SelectedLifestylesListItemLightboxBottomButtons';

import { AlertMessage } from '../../../../../helpers/AlertMessage';
import { LoadingSkeleton } from '../../../../../helpers/LoadingSkeleton';
import { Loading } from '../../../../../helpers/Loading';
import { NoResults } from '../../../../../helpers/NoResults';

import {
  LightboxWrapper,
  useLightboxWrapper,
  LightboxRenderCustomButtons,
  LightboxRenderImageTitle
} from '../../../../../helpers/LightboxWrapper';

import { Files } from '../../../../../utils/Files';

import {
  SelectedLifestylesListLifestylesSet,
  SelectedLifestylesListLifestylesSetSelectedLifestyles,
  SelectedLifestylesListLifestylesSetSelectedLifestyle,
  SelectedLifestylesListUpdateLifestylesSetCache
} from './SelectedLifestylesList.types';

interface SelectedLifestylesListProps {
  lifestylesSet: SelectedLifestylesListLifestylesSet;
  lifestylesSetFetched: IsFetched;
  lifestylesSetError: ErrorMessage;
  lifestylesSetIsPlaceholderData: IsPlaceholderData;
  lifestylesCacheKeys?: FetchLifestylesCacheKeys;
  lifestylesSetCacheKeys?: FetchLifestylesSetsCacheKeys;
  onSelectedLifestylesSidebarClose: OnSelectedLifestylesSidebarCloseAction;
  updateLifestylesSetCache: SelectedLifestylesListUpdateLifestylesSetCache;
  togglePreventModalClose?: TogglePreventModalCloseAction;
}

function SelectedLifestylesList({
  lifestylesSet,
  lifestylesSetFetched,
  lifestylesSetError,
  lifestylesSetIsPlaceholderData,
  lifestylesCacheKeys,
  lifestylesSetCacheKeys,
  onSelectedLifestylesSidebarClose,
  updateLifestylesSetCache,
  togglePreventModalClose
}: SelectedLifestylesListProps) {
  const selectedLifestyles =
    useMemo<SelectedLifestylesListLifestylesSetSelectedLifestyles>(
      () => lifestylesSet?.selectedLifestyles || [],
      [lifestylesSet]
    );

  const selectedLifestylesTotalCount = size(selectedLifestyles);

  const selectedLifestylesInLightbox =
    useMemo<SelectedLifestylesListLifestylesSetSelectedLifestyles>(() => {
      return filter<SelectedLifestylesListLifestylesSetSelectedLifestyle>(
        selectedLifestyles,
        (selectedLifestyle) =>
          selectedLifestyle.lifestyle?.image?.file &&
          Files.isImage(selectedLifestyle.lifestyle.image.file)
      );
    }, [selectedLifestyles]);

  const lightboxItems = useMemo(() => {
    const lifestyleImages = selectedLifestylesInLightbox.map(
      (selectedLifestyle) =>
        selectedLifestyle.lifestyle?.image?.file &&
        Files.isImage(selectedLifestyle.lifestyle.image.file)
          ? selectedLifestyle.lifestyle.image
          : null
    );

    return compact(lifestyleImages);
  }, [selectedLifestylesInLightbox]);

  const {
    handleLightboxClose,
    handleLightboxNext,
    handleLightboxOpen,
    handleLightboxOpenOnSlide,
    handleLightboxPrev,
    index,
    imageItem,
    lightBoxOpened,
    mainSrc,
    prevSrc,
    nextSrc
  } = useLightboxWrapper({
    items: lightboxItems,
    toggleBackdrop: togglePreventModalClose
  });

  const renderLightboxButtons = useCallback<LightboxRenderCustomButtons>(
    ({ index }) => {
      const selectedLifestyle = selectedLifestylesInLightbox[index];
      if (!selectedLifestyle) {
        return [];
      }

      return (
        <SelectedLifestylesListItemLightboxBottomButtons
          selectedLifestyle={selectedLifestyle}
          lifestylesSet={lifestylesSet}
          lifestylesCacheKeys={lifestylesCacheKeys}
          lifestylesSetCacheKeys={lifestylesSetCacheKeys}
          onSelectedLifestylesSidebarClose={onSelectedLifestylesSidebarClose}
          updateLifestylesSetCache={updateLifestylesSetCache}
        />
      );
    },
    [
      selectedLifestylesInLightbox,
      lifestylesSet,
      lifestylesCacheKeys,
      lifestylesSetCacheKeys,
      onSelectedLifestylesSidebarClose,
      updateLifestylesSetCache
    ]
  );

  const renderImageTitle = useCallback<LightboxRenderImageTitle>(
    ({ index }) => {
      const selectedLifestyle = selectedLifestylesInLightbox[index];
      if (!selectedLifestyle) {
        return null;
      }

      return (
        <SelectedLifestylesListItemLightboxTitle
          selectedLifestyle={selectedLifestyle}
        />
      );
    },
    [selectedLifestylesInLightbox]
  );

  return (
    <Fragment>
      <AlertMessage addClassName="m-4" message={lifestylesSetError} />
      <Loading loaded={!lifestylesSetIsPlaceholderData} />
      <LoadingSkeleton
        loaded={lifestylesSetIsPlaceholderData || lifestylesSetFetched}
      >
        {selectedLifestylesTotalCount === 0 ? (
          <NoResults addErrorClassName="m-4" />
        ) : (
          <ul className="space-y-4">
            {selectedLifestyles.map((selectedLifestyle) => (
              <SelectedLifestylesListItem
                key={selectedLifestyle.uuid}
                lifestylesSet={lifestylesSet}
                selectedLifestyle={selectedLifestyle}
                lifestylesSetCacheKeys={lifestylesSetCacheKeys}
                onLightboxOpen={handleLightboxOpenOnSlide}
                onSelectedLifestylesSidebarClose={
                  onSelectedLifestylesSidebarClose
                }
                updateLifestylesSetCache={updateLifestylesSetCache}
              />
            ))}
          </ul>
        )}
      </LoadingSkeleton>

      <LightboxWrapper
        handleLightboxClose={handleLightboxClose}
        handleLightboxNext={handleLightboxNext}
        handleLightboxOpen={handleLightboxOpen}
        handleLightboxPrev={handleLightboxPrev}
        index={index}
        imageItem={imageItem}
        lightBoxOpened={lightBoxOpened}
        mainSrc={mainSrc}
        nextSrc={nextSrc}
        prevSrc={prevSrc}
        renderImageTitle={renderImageTitle}
        renderCustomButtons={renderLightboxButtons}
        withFullScreenButton
      />
    </Fragment>
  );
}

export default memo<SelectedLifestylesListProps>(SelectedLifestylesList);
